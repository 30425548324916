import Vue from "vue";
const state = {
  IsSuccess:false,
  LoginInfo: {
    user_id: '',
    user_name: '',
    user_type: '',
    user_type_name: ''
  }
}
// 状态变更函数
const mutations = {
  UpdateLoginInfo(state, val) {
    Vue.set(state, 'LoginInfo', {
      user_id: val.user_id,
      user_name: val.user_name,
      user_type: val.user_type,
      user_type_name: {
        normal: '用户登录',
        token: '秘钥登录'
      } [val.user_type]
    })
    Vue.set(state,'IsSuccess',true);
  }
}

const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
// std-table/index.vue
<template>
  <transition name="datalist">
    <el-container class="std-table" style="text-align:left;" :style="{height:height||'100%'}">
      <div style="display:none;">
        <slot v-if="true"></slot>
      </div>
      <el-header ref="buttons">
        <el-button type="primary" size="small" v-for="(btn,index) in btns" :key="index" :icon="btn.icon" @click="onClick(btn.handler);"
          :disabled="btnMode[{1:'_1',2:'_2'}[btn.mode]||'_0']">
          {{btn.text}}
        </el-button>
        <slot name="btn_bar" />
      </el-header>
      <el-main style="display:flex;">
        <el-table v-if="showtable" :data="CurrentPageData" stripe :header-cell-style="{background:'#eef1f6',color:'#606266'}" ref="datalist"
          highlight-current-row @current-change="selectrow" @selection-change="handleSelectionChange" :height="autoHeight?'auto':'100%'"
          header-cell-class-name="stdtable-header-th" :tree-props="treeProps" :row-style="rowStyle" :row-class-name="rowClassName"
          :default-expand-all="defaultExpandAll" :row-key="rowKey">
          <el-table-column v-if="!disableSelection" type="selection" :selectable="onAllowSelect" width="40"></el-table-column>
          <el-table-column v-for="(col,index) in cols" :key="index" :prop="col.prop" :label="col.label" show-overflow-tooltip :width="col.width"
            :min-width="col.minWidth">
            <template slot-scope="scope">
              <std-table-c :col="col" :row="scope.row" :scope="scope" :rowindex="scope.$index" :func="t1"></std-table-c>
              <!-- <slot v-if="col.format!=null"
                    :name="col.format"
                    :row="scope.row"
                    :rowindex="scope.$index"></slot>
              <span v-else>{{scope.row|display_info(col)}}</span> -->
            </template>
            <template slot="header" slot-scope="scope">
              <template v-if="hasFilterColumn">
                <!-- 如果是文本类型的筛选 -->
                <div v-if="col.filter_type=='text'" style="width:100%;">
                  <!-- <div style="display: block;height:20px;"
                       v-text="col.label"></div> -->
                  <header-title :label="col.label" />
                  <el-input :placeholder="col.tips" v-model="dftFilter[index]" size="mini">
                  </el-input>
                </div>
                <!-- 如果是自定义头部 -->
                <slot v-else-if="col.header!=null" :name="col.header" :row="scope.row" :rowindex="scope.$index" :col="col"></slot>
                <!-- 如果没有控制 -->
                <div v-else style="width:100%;">
                  <!-- <div style="display:block;"
                       v-text="col.label"></div> -->
                  <header-title :label="col.label" />
                  <el-input :placeholder="col.tips" disabled size="mini">
                  </el-input>
                </div>
              </template>
              <header-title v-else :label="col.label" />
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer v-if="paging||remotePaging">
        <el-pagination background :current-page.sync="pagerPageIndex" :page-sizes="[pagesize,10,20,50,100]" :page-size.sync="current_pagesize"
          layout="total, sizes, prev, pager, next, jumper, slot" @current-change="onCurrentPageChange" :total="paging?ShowData.length:dataTotal">
          <el-button v-if="_events.refresh" icon="el-icon-refresh" size="small" @click="onRefresh"></el-button>
        </el-pagination>
      </el-footer>
    </el-container>
  </transition>
</template>

<script>
import Sortable from 'sortablejs'
export default {
  name: 'std-table',
  tag_name: 'std-table',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    //启用筛选
    enableHeaderFilter: {
      type: Boolean,
      default: false
    },
    //自定义筛选,返回一个{xx:xx}对象
    filterFunction: Function,
    //强制通过隐藏再显示的方式刷新ui(当出现组件高度无法动态更新时使用)
    forceRefresh: {
      type: Boolean,
      default: false
    },
    autoHeight: {
      type: Boolean,
      default: true
    },
    rowStyle: null,
    rowClassName: null,
    //按钮列表
    btns: Array,
    /**
     * 表格可用的列列表
     *
     * 可选参数说明
     * prop:绑定的属性名
     * label:显示的描述
     * format:使用指定的插槽
     * width:列宽度
     * tips:筛选框的输入提示
     * date_format:日期格式
     * filter_type:筛选框的类型,可选值:text,drop,date,daterange
     * show_type
     */
    cols: Array,
    disableSelection: {
      type: Boolean,
      default: false
    },
    //是否控制某行的允许选中行为
    allowSelectFunction: {
      type: Function,
      default: null
    },
    //本地分页,如果同时传入paging和remotePaging时选paging
    paging: {
      type: Boolean,
      default: false
    },
    //远程分页
    remotePaging: {
      type: Boolean,
      default: false
    },
    //远程分页时需要传入记录条数
    dataTotal: {
      type: Number,
      default: 0
    },
    allowSortRow: {
      type: Boolean,
      default: false
    },
    height: {
      type: String
    },
    //当前页码
    pageindex: {
      type: Number,
      default: 1
    },
    pagesize: {
      type: Number,
      default: 20
    },
    treeProps: {
      type: Object,
      default: () => { }
    },
    defaultExpandAll: Boolean,
    rowKey: {
      type: String
    },
    tableEvent: Object,
  },
  components: {
    'std-table-c': {
      functional: true,
      render(h, context) {
        return context.props.func(h, context)
      }
    },
    headerTitle: {
      props: {
        label: String
      },
      template: '<div style="display: block;height:20px;" v-text="label"></div>'
    }
  },
  created() {
    for (const [index, col] of this.cols.entries()) {
      if (col.filter_type) {
        this.$set(this.dftFilter, index, '')
      }
    }
  },
  data() {
    return {
      showtable: true,
      //默认的数据筛选器
      dftFilter: {},
      //当前页码大小

      //已选中的行
      multipleSelection: [],
      //按钮模式,0:不控制,1:单选,2:多选
      btnMode: {
        _0: false,
        _1: true,
        _2: true
      },
      //通过子元素绑定的数据
      elBindData: {
        buttons: null,
        columns: null
      }
    }
  },
  watch: {
    ShowData() {
      if (this.forceRefresh) {
        this.$nextTick(() => {
          this.showtable = false
          this.$nextTick(() => {
            this.showtable = true
          })
        })
      }
    }
  },
  computed: {
    ShowData() {
      if (!this.Bus.isType('Array', this.data)) {
        this.Bus.msg_waring('表格控件绑定了无效的数据')
        return []
      }
      //检查查询条件,进行筛选返回结果
      const filters = []
      let filter;
      if (this.filterFunction != null) {
        filter = this.filterFunction()
      } else {
        filter = this.dftFilter
      }
      
      for (const key in filter) {
        if (filter.hasOwnProperty(key)) {
          //要筛选的值
          let element = filter[key]
          if (element !== null && element !== '' && element != undefined) {
            //let prop = this.cols[key].prop
            let prop = key
            filters.push({ name: prop, value: new RegExp(element, 'i') })
          }
        }
      }
      let result
      if (filters.length > 0) {
        //返回满足所有筛选条件的记录
        result = this.data.filter(now =>
          filters.every(f => now[f.name] != null && f.value.test(now[f.name]))
        )
      } else {
        result = this.data
      }

      return result
    },
    current_pagesize: {
      get() {
        return this.pagesize
      },
      set(value) {
        this.$emit('update:pagesize', value)
      }
    },
    pagerPageIndex: {
      get() {
        return this.pageindex
      },
      set(value) {
        this.$emit('update:pageindex', value)
      }
    },
    CurrentPageData() {
      if (this.paging && !this.remotePaging) {
        return this.ShowData.slice(
          (this.pageindex - 1) * this.current_pagesize,
          this.pageindex * this.current_pagesize
        )
      } else {
        return this.ShowData
      }
    },
    //检查是否有筛选列,如果有,那么部分列不筛选也需要显示一个禁用框
    hasFilterColumn() {
      if (this.enableHeaderFilter) {
        return true
      }
      let cols = this.cols
      for (const col of cols) {
        if (col.filter_type) {
          return true
        }
      }
      return false
    },
    //实际显示的按钮列表
    //ShowButtons () { }
  },
  methods: {
    display_info(context) {
      let me = context.props
      let row = me.row
      let config = me.col
      if (config.show_type == 'option') {
        let options = config.options
        let textField = config.option_text || 'text'
        let valueField = config.option_value || 'value'
        //拼接为{值1:值1配置,值2:值2配置}的格式
        let raw_options
        //对象类型只能配置显示值,没有其他扩展功能
        if (options.constructor === Object) {
          let temp = {}
          for (const item in options) {
            temp[item] = { text: options[item], value: item }
          }
          raw_options = temp
        }
        //数组类型可以按进度添加各种扩展属性
        if (options.constructor === Array) {
          let temp = {}
          for (const item of options) {
            temp[item[valueField]] = item
          }
          raw_options = temp
        }
        if (raw_options[row[config.prop]] != null) {
          //{text:"",value:"",color:""}
          let now_value = row[config.prop]
          if (now_value == null) return ''
          let now_option = raw_options[now_value.toString()]
          if (now_option.color != null) {
            return (
              <span style={{ color: now_option.color }}>
                {now_option[textField]}
              </span>
            )
          }
          return now_option[textField]
        }
        return ''
      }
      if (config.show_type == 'display') {
        return row[config.display_prop]
      }
      return row[config.prop]
    },
    t1(h, context) {
      let { col, row, rowindex, scope } = context.props
      let display_info = this.display_info

      if (col.format) {
        return context.parent.$scopedSlots[col.format]({
          name: col.format,
          row: row,
          rowindex: rowindex,
          col: col
        })
      } else if (col.template) {
        let x = <span></span>
        let tpl_result = col.template(scope)
        if ([String, Number].indexOf(tpl_result.constructor) != -1) {
          return h('span', tpl_result)
        }
        return tpl_result
      } else {
        let dinfo = display_info(context)
        if (
          dinfo != null &&
          [String, Number].indexOf(dinfo.constructor) != -1
        ) {
          return h('span', dinfo)
        }
        return dinfo
      }
    },
    //是否允许某一行选中
    onAllowSelect(row, index) {
      if (this.allowSelectFunction != null) {
        return this.allowSelectFunction(row, index)
      }
      return true
    },
    //公开方法,获取分页信息
    getPageInfo() {
      let { pageindex, current_pagesize, ShowData, dataTotal } = this
      return {
        pageindex,
        pagesize: current_pagesize,
        total: this.paging ? ShowData.length : dataTotal
      }
    },
    onClick(bindName) {
      //debugger;
      if (this.Bus.isType('Function', bindName)) {
        bindName()
      } else {
        if (this.$parent[bindName]) {
          this.$parent[bindName]()
        }
      }
    },
    selectrow(currentRow, oldCurrentRow) {
      this.data.forEach(p => {
        if (this.onAllowSelect(currentRow)) {
          this.$refs.datalist.toggleRowSelection(p, p == currentRow)
        }
      })
    },
    handleSelectionChange(val) {
      let me = this
      this.multipleSelection = val
      this.btnMode._1 = val.length != 1
      this.btnMode._2 = val.length == 0

      this.$emit('selection-change', this.multipleSelection)
    },
    onRowDrop() {
      const tbody = this.$refs.datalist.$el.querySelector(
        '.el-table__body-wrapper tbody'
      )
      // 防止部分浏览器拖动后打开一个新窗口
      this.$refs.datalist.$el.ondrop = function (event) {
        event.preventDefault()
        event.stopPropagation()
      }
      const _this = this
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          if (newIndex == oldIndex) return
          // 当sortablejs会直接修改dom排序,会导致vue的绑定更新时出现排序错误,所以需要先还原dom操作
          let $li = tbody.children[newIndex],
            $oldLi = tbody.children[oldIndex]
          // 先删除移动的节点
          tbody.removeChild($li)
          // 再插入移动的节点到原有节点，还原了移动的操作
          if (newIndex > oldIndex) {
            tbody.insertBefore($li, $oldLi)
          } else {
            tbody.insertBefore($li, $oldLi.nextSibling)
          }

          const currRow = _this.data.splice(oldIndex, 1)[0]
          _this.data.splice(newIndex, 0, currRow)
        }
      })
    },
    onCurrentPageChange: function () {
      this.onRefresh()
    },
    onRefresh() {
      let me = this
      me.$emit('refresh', {
        pageindex: me.pageindex,
        pagesize: me.current_pagesize,
        page_type: me.remotePaging ? 'remote' : me.paging ? 'local' : 'none'
      })
    }
  },
  mounted() {
    let me = this
    //单行拖动
    if (me.allowSortRow) {
      this.onRowDrop()
    }
    //读取默认插槽的元素,获取绑定数据
    // console.log(this)
    // if (me.$slot&&me.$slot.default) {
    //   //能够识别的标签类型
    //   let allow_tag = {
    //     'std-table-btns': 'buttons'
    //   }
    //   //遍历子元素,获取有效的子元素
    //   for (const node of me.$slot.default) {
    //     const option = node.componentOptions
    //     //寻找支持的标签类型
    //     if (option && allow_tag[option.tag]) {
    //       me.elBindData[allow_tag[option.tag]] = node
    //     }
    //   }

    //   //根据实际子元素类型获取配置
    //   //按钮
    //   if(me.elBindData.buttons){
    //     for (const btn of me.elBindData.buttons) {
    //       console.log(btn)
    //     }
    //   }
    // }

    // let event = this.tableEvent
    // if (event) {
    //   for (const ename of Object.keys(event)) {
    //     this.$refs.datalist.$on(ename, event[ename])
    //   }
    // }
    //console.log(this)
  }
}
</script>
<style scoped>
.el-header {
  margin: 5px 10px;
}
.el-header:empty {
  display: none;
}
.el-footer {
  margin: 3px 0px;
}
</style>
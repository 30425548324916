import Vue from "vue";
import Vuex from "vuex";
import getters from './getters'

Vue.use(Vuex);

// 读取同级的modules文件夹下的所有js文件
const modulesFiles = require.context('./modules', true, /\.js$/)

// 自动将这些js文件加载为以文件名命名的vuex模块
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default new Vuex.Store({
  state: {
    LoginState: '',
    isInitLoginState: false
  },
  mutations: {
    UpdateLoginState(state, token) {
      state.LoginState = token
      localStorage.LoginState = token
    },
    //初始化登录信息
    InitLoginState(state) {
      if (!state.isInitLoginState) {
        state.isInitLoginState = true
        state.LoginState = localStorage.LoginState
      }
    }
  },
  actions: {},
  modules: modules,
  getters: getters
});
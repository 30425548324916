// home/index
//首页
<template>
  <el-container class="homebox" v-if="IsSuccess">
    <el-header>
      <div style="display: inline-block;color: white;padding-left: 10px;cursor: pointer;" @click="onShowMenu">
        <i class="el-icon-s-unfold"></i>&nbsp;菜单
      </div>
      <el-image src="favicon.ico" fit="contain" class="logo" />
      <span class="logo_text">支付即积分管理</span>
      <div class="btns">
        <!-- <el-dropdown>
          <el-link type="info" :underline="false">扩展功能<i class="el-icon-arrow-down el-icon--right"></i></el-link>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>日志管理</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
      <div class="tools">
        <!-- <el-link type=info :underline="false">帮助</el-link>
        <el-link type=info :underline="false">操作指南</el-link> -->
        <el-dropdown class="user" @command="onUserAction">
          <el-link type="info" :underline="false">
            <i class="el-icon-user-solid icon"></i>
            {{UserData.user_name}}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-link>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item><i class="el-icon-user"></i>个人资料</el-dropdown-item>
            <el-dropdown-item command="exit">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-main style="padding:20px 0 0 0;background-image: linear-gradient(#262f3e, #fff 50%);">
      <el-scrollbar class="myscroll1" style="height:100%;">
        <!-- 管理员首页 -->
        <template v-if="ishome">
          <HomePageIndex></HomePageIndex>
        </template>
        <router-view v-else />
      </el-scrollbar>
      <el-drawer title="菜单" :visible.sync="showmenu" :with-header="false" size="200px" :modal="false" class="homepage-menu" :direction="'ltr'">
        <el-menu default-active="1">
          <el-menu-item index="1">
            <i class="el-icon-menu"></i>
            <el-link slot="title" @click="$router.push({path: '/'})" style="text-decoration:none;color:#303133;">店铺绑定</el-link>
          </el-menu-item>
          <el-menu-item index="2">
            <i class="el-icon-menu"></i>
            <el-link slot="title" @click="$router.push({path: '/sysconfig/index'})" style="text-decoration:none;color:#303133;">系统设置</el-link>
          </el-menu-item>
          <el-menu-item index="3">
            <i class="el-icon-menu"></i>
            <el-link slot="title" @click="$router.push({path: '/log/callback_txt'})" style="text-decoration:none;color:#303133;">文本日志</el-link>
          </el-menu-item>
        </el-menu>
      </el-drawer>
    </el-main>
  </el-container>
</template>

<script>
import base from '@/assets/styles/base.scss'
import HomePageIndex from './HomePage/index'
import customIndex from './HomePage/custom-index'
export default {
  name: 'Home',
  components: {
    HomePageIndex
  },
  data() {
    return {
      showmenu: false
    }
  },
  computed: {
    ishome() {
      return this.$route.name === "Home"
    },
    UserData() {
      return this.$store.state.global.LoginInfo
    },
    //判断显示页面的前置条件是否完成(如,登录检查)
    IsSuccess() {
      return this.$store.state.global.IsSuccess
    }
  },
  methods: {
    onShowMenu() {
      this.showmenu = true
    },
    onUserAction(cmd) {
      if (cmd == "exit") {
        this.$confirm('确认退出?', '提示', {})
          .then(() => {
            //sessionStorage.removeItem('user')
            this.$router.push({
              name: 'Login'
            })
          })
          .catch(() => { })
      }
    }
  },
  created() {
    //console.log(this.$router,this.$route)
  },
  mounted: function () {
    let me = this
    this.Bus.ActionFunc("Home-GetBase", {}, data => {
      me.$store.commit('global/UpdateLoginInfo', {
        user_id: data.user.user_id,
        user_name: data.user.user_name
      })
    })
  }
}
</script>
<style lang="scss">
.el-scrollbar > :first-child {
  height: calc(100% + 17px);
}
.homebox {
  height: 100%;
  > .el-header {
    height: 50px !important;
    padding: 10px 0px;
    background: #1a212d;
    line-height: 30px;
    .logo {
      height: 100%;
      margin-left: 20px;
      vertical-align: top;
      img {
        width: auto;
      }
    }
    .logo_text {
      line-height: 30px;
      color: #f9f9f9;
    }
    .btns {
      display: inline-block;
      margin-left: 20px;
      > .el-link {
        margin-right: 10px;
        &.el-link--info:visited,
        &.el-link--info:link {
          color: #d3d5d9;
        }
      }
    }
    .tools {
      float: right;
      margin-right: 20px;
      > .user {
        margin-left: 20px;
        .icon {
          color: white;
          margin-right: 5px;
          font-size: 25px;
          vertical-align: middle;
        }
      }
    }
  }
}
.homepage-menu {
  .el-drawer > .el-drawer__header {
    height: 0px;
    margin: 0px;
    padding: 0px;
    display: none;
  }
}
</style>

<template>
  <el-breadcrumb separator-class="el-icon-arrow-right" style="height:20px;" class="std-nav">
    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item v-for="(p,i) in paths" :key="i" v-bind="getPath(p)">{{getText(p)}}</el-breadcrumb-item>
  </el-breadcrumb>
</template>
<script>
export default {
  name: 'std-nav',
  tag_name: 'std-nav',
  props: {
    paths: Array
  },
  data() {
    return {

    }
  },
  methods: {
    getPath(path) {
      if (!this.Bus.isEmpty(path.name)) {
        return { to: { name: path.name } }
      }
      if (!this.Bus.isEmpty(path.path)) {
        return { to: { path: path.path } }
      }
      return {}
    },
    getText(path) {
      if (this.Bus.isType('String', path)) {
        return path
      }
      return path.text
    }
  }
}
</script>
<style lang="scss">
.std-nav {
  margin-bottom: 5px;
  .el-breadcrumb__inner {
    color: white;
    &:not(.is-link) {
      color: white !important;
    }
  }
}
</style>
import StdTableBtn from './btn'
export default{
  name:'StdTableBtns',
  tag_name:'std-table-btns',
  functional:true,
  render(h,context){
    console.log('std-table-btns',context)
    // return this.$scopedSlots.default({
    //   //btnlist:
    // })
  },
  methods: {
    GetBtns(){
      return this.$children
    }
  },
  mounted() {
    
  }
}
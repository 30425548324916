import http from '@/components/utils/http.js'
import Bus from '@/components/utils/Bus.js'
import {
  Message
} from 'element-ui';
import router from '@/router'
// 默认参数
const state = {

}
// 状态变更函数
const mutations = {

}

// 异步函数操作
const actions = {
  action(context, {
    method,
    data,
    callback,
    err_callback,
    request_err_callback
  }) {
    http.defaults.headers.common['Authorization'] = 'bearer ' + Bus.vueObj.$store.state.LoginState
    //let url = `${Bus.vueObj.$store.getters.ApiPath}/values/dft/${method}`
    let url = `./Api/${method}`

    return http.post(url, {
        data: data || {}
      })
      .then(function (response) {
        let res = response.data
        if (!res) {
          Message.error('未收到服务器响应,请检查网络是否异常或稍后重试!')
          return
        }
        if (res.status == '0') {
          if (callback) {
            callback(res.data, {
              status:res.status,
              message:res.message
            })
          }
        } else {
          if (res.status == '10001') {
            sessionStorage.setItem('user', '')
            console.log('t:'+res.message)
            router.push({
              name: 'Login',
              params:{
                errorMessage:res.message
              }
            })
            return
          } else {
            Message.error(res.message)
          }

          if (err_callback) {
            err_callback(res.data, {
              status:res.status,
              message:res.message
            });
          }
        }
      }, function (error) {
        console.log(error)

        if (request_err_callback) {
          request_err_callback(error);
        }
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
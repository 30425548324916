// import http from '@/components/utils/http.js'
// import Vue from 'vue'
const Bus = {
  ActionFunc(funcName, postData, refunc, err_callback,request_err_callback) {
    return this.vueObj.$store.dispatch('api/action', {
      method: funcName,
      data: postData || {},
      callback: refunc,
      err_callback: err_callback,
      request_err_callback:request_err_callback
    })
  },
  Action(config) {
    return this.vueObj.$store.dispatch('api/action', config)
  },
  // 判断数据类型
  // 可选值:String,Number,Boolean,Undefined,Null,Object,Function,Array,Date,RegExp
  isType(type, obj) {
    return Object.prototype.toString.call(obj) === `[object ${type}]`;
  },
  validData(data, rules) {
    //类型:非空验证(数组必须非非空数组),正则验证,值范围验证,自定义函数验证
    // rules = {
    //   show_msg: false, // 默认为显示,可主动设置不自主弹窗
    //   get_errlist: false, // 在有异常时不返回false而是返回错误列表
    //   require: [{
    //     prop: "",// 属性名称,会从data中获取对应属性名的数据,如果有填写prop将忽略data的值
    //     data:"",// 要验证的数据
    //     name: "",// 属性描述,自动拼错误提示
    //     msg: ""// 自定义消息,包含自定义消息时不使用默认错误消息
    //   }],
    //   reg: [{
    //     prop: "",
    //     data:"",// 正则验证的数据必须为字符串类型
    //     rule: "",// 预设公式id
    //     reg: "",// 正则公式对象或正则公式字符串(不带前后斜杠)
    //     nullable: false,// 是否允许为空时不验证
    //     name: "",
    //     msg: ""
    //   }],
    //   range: [{
    //     prop: "",
    //     data:"",
    //     range: [],// 值的可选范围
    //     nullable: false,
    //     name: "",
    //     msg: ""
    //   }],
    //   func: [{
    //     prop: "",
    //     data:"",
    //     func: () => {},// 验证数据的函数,如果会false或非空字符串则表示验证不通过
    //     name: "",
    //     msg: ""
    //   }],
    // }
    let isType = this.isType
    let isEmpty = this.isEmpty
    let valiResult = []
    let saveError = (type, rule, errmsg) => {
      valiResult.push({
        type,
        prop: rule.prop,
        msg: !isEmpty(rule.msg) ?
          `[<span class="validata_errs_prop">${rule.name}</span>] ${rule.msg}` : `[<span class="validata_errs_prop">${rule.name}</span>] ${errmsg}`
      })
    }
    if (!isType('Object', data) || !isType('Object', rules)) {
      throw new Error('数据验证功能收到无效的验证数据')
    }
    if (rules.require) {
      for (let r of rules.require) {
        let val = r.prop == undefined ? r.data : data[r.prop]
        if (isType('String', val) && (val == null || val.trim() == '')) {
          saveError('require', r, '不能为空')
          continue
        }
        if (!val) {
          saveError('require', r, '不能为空')
          continue
        }
        if (Array.isArray(val) && val.length == 0) {
          saveError('require', r, '不能为空')
          continue
        }
      }
    }
    if (rules.reg) {
      for (let r of rules.reg) {
        let val = r.prop == undefined ? r.data : data[r.prop]
        if (val == null && r.nullable === true) {
          continue
        }
        if (!isType('String', val)) {
          saveError('reg', r, '数据无效')
          continue
        }
        if (r.rule) {
          //查找默认公式
          //r.reg=""
        }
        if (!isType('String', r.reg)) {
          r.reg = new RegExp(r.reg)
        }
        if (!r.reg.test(val)) {
          saveError('reg', r, '验证未通过')
          continue
        }
      }
    }
    if (rules.range) {
      for (let r of rules.range) {
        let val = r.prop == undefined ? r.data : data[r.prop]
        if (val == null && r.nullable === true) {
          continue
        }
        if (r.value_type == String) {
          val = val.toString();
          r.range = r.range.map(_ => _.toString());
        }
        if (r.value_type == Number) {
          val = Number(val)
          r.range = r.range.map(_ => Number(_));
        }
        if (r.range.indexOf(val) == -1) {
          saveError('range', r, '值不在可选范围内')
          continue
        }
      }
    }
    if (rules.func) {
      for (const r of rules.func) {
        let func_result = r.func(r.prop == undefined ? r.data : data[r.prop])
        if (func_result === false) {
          saveError('func', r, '数据无效')
          continue
        }
        if (isType('String', func_result) && !isEmpty(func_result)) {
          saveError('func', r, func_result)
          continue
        }
      }
    }
    if (valiResult.length > 0) {
      if (rules.show_msg != false) {
        let errmsg = '<ul class="validata_errs">'
        for (const err of valiResult) {
          errmsg += `<li>${err.msg}</li>`
        }
        errmsg += '</ul>'
        this.vueObj.$alert(errmsg, '提交的数据包含无效项', {
          dangerouslyUseHTMLString: true
        })
      }
      if (rules.get_errlist === true) {
        return valiResult
      }
      return false
    }
    return true
  },
  msg_waring(msg) {
    this.vueObj.$message({
      message: msg,
      type: 'warning'
    })
  },
  msg_success(msg) {
    this.vueObj.$message({
      message: msg,
      type: "success"
    })
  },
  msg_confirm(msg, success, error) {
    this.vueObj.$confirm(msg, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      success()
    }).catch((e) => {
      if (e == "cancel") {
        if(error){
          error()
        }
      }else{
        throw e;
      }
    });
  },

  isEmpty(value, nullObj) {
    if (value == null || value == undefined || value == "") {
      return true
    }
    if (nullObj) {
      switch (value.constructor) {
        default:
          return false
        case Number:
          return value == 0
        case String:
          return value.trim() == ""
        case Array:
          return value.length == 0
        case Object:
          return Object.keys(value).length == 0
      }
    }
  },
  //按规则和现有集合生成名称
  getDescName(ary, descPrex, func) {
    let descNumMax = 0;
    var reg = (new RegExp(`^${descPrex}\\d+$`))
    ary.forEach(ctr => {
      let text = func(ctr)
      if (text === false) {
        return
      }
      if (reg.test(text)) {
        let tnum = text.substr(descPrex.length);
        if (tnum > descNumMax) {
          descNumMax = parseInt(tnum)
        }
      }
    })
    return descPrex + (descNumMax + 1)
  },

  toDropData(value) {
    let ary = []
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        const element = value[key]
        ary.push({
          text: element,
          value: key
        })
      }
    }
    return ary
  },
  hasEmpty(...values) {
    if (values == null || values.length == 0) {
      return false
    }
    for (const value of values) {
      if (this.isEmpty(value)) {
        return true
      }
    }
    return false
  }
}

export default Bus;
<template>
  <el-card class="std-functions">
    <template #header>常用功能</template>
    <el-button size="mini" round @click="onToUrl('/sysconfig')">系统相关设置</el-button>
    <el-button size="mini" round @click="onToUrl('/log/callback_txt')">文本回调日志</el-button>
    <!-- <el-link :underline="false" href="/sysconfig">系统相关设置</el-link>
        <el-link :underline="false" href="/log/callback">收到回调日志</el-link> -->
  </el-card>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    onToUrl(url) {
      this.$router.push({
        path: url
      })
      //location.href='/#'+url
    }
  }
}
</script>
<style lang="scss">
.std-functions {
  margin-top:5px;
  .el-link {
    display: block;
  }
  .el-button {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 5px;
  }
}
</style>
import Bus from '@/components/utils/Bus.js'
// 一些未写入具体模块的数据筛选器
const getters = {
  // 示例
  // sidebar: state => state.app.sidebar

  LoginInfo(state){
    var tk=state.LoginState
    let result={
      IsLogin:false,
      Code:'',
      Name:''
    }
    
    if(tk==null||tk==''){
      return result;
    }
     try{
       let tkary=tk.split('.');
       let loginInfo=JSON.parse(window.atob(tkary[1]))
       result.IsLogin=true,
       result.Code=loginInfo.usercode
       result.Name=loginInfo.username
     }catch(e){
       console.log("登录信息解码失败:",e)
     }
     return result;
  },
  ApiPath(state){
    let p= process.env.VUE_APP_API_PATH
    if(!p){
      return ''
    }
    return p
  }
}
export default getters

<template>
  <el-container class="admin-index-main">
    <el-main>
      <std-nav :paths="navData"></std-nav>
      <el-card class="app-search">
        <div class="flexbox">
          <div class="flex-1">
            <el-input v-model="searchKey" :placeholder="searchTips">
              <el-button slot="append" icon="el-icon-search" @click="onRefresh"></el-button>
            </el-input>
          </div>
          <!-- <el-button type="text">高级搜索</el-button> -->
        </div>
      </el-card>
      <el-card class="app-list">
        <std-table ref="module_table" :cols="userConfig.cols" :data="data.stores" :pageindex.sync="userConfig.pageIndex"
          :pagesize.sync="userConfig.pageSize" :dataTotal="data.store_total" disable-selection remotePaging force-refresh @refresh="onRefresh">
          <template #action="{row}">
            <el-link icon="el-icon-edit" @click="onEdit(row)">修改</el-link>
            <el-link icon="el-icon-delete" @click="onDelete(row)" style="margin-left:5px;">移除</el-link>
          </template>
        </std-table>
      </el-card>
      <component v-if="dialog.show" :is="dialog.type" :windata="dialog.data" @cancel="onCloseDialog" @success="dialog.event"></component>
    </el-main>
    <el-aside>
      <el-card class="app-mgr">
        <template #header>
          基础操作
        </template>
        <el-button type="primary" size="mini" icon="el-icon-plus" @click="onCreateNew">添加绑定</el-button>
        <div class="tips">
          添加一个新的店铺绑定,已绑定的店铺不能重复绑定
        </div>
      </el-card>
      <normal-card></normal-card>
    </el-aside>
  </el-container>
</template>
<script>
import winStoreBind from './win-store-bind'
export default {
  components: {
    winStoreBind
  },
  data() {
    return {
      //导航栏
      navData: [
        '已绑定店铺列表'
      ],
      //搜索关键字
      searchKey: '',
      searchTips: '请输入要搜索的 crm店铺号/店铺名/线上店铺号',
      userConfig: {
        cols: [
          { prop: 'crm_store_id', label: 'crm店铺号' },
          { prop: 'crm_store_name', label: 'crm店铺名' },
          { prop: 'online_id', label: '线上店铺号' },
          { prop: 'create_time', label: '绑定时间', width: 180 },
          { format: 'action', width: 120 }
        ],
        pageIndex: 1,
        pageSize: 20
      },
      dialog: {
        show: false,
        type: '',
        data: {},
        event: null
      },
      data: {
        stores: [],
        store_total: 0,
        crm_store_info: null
      },
      FuncButtons: [],
      userinfo: {},
      // 公告
      notices: []
    }
  },
  computed: {
    UserData() {
      return this.$store.state.global.LoginInfo
    }
  },
  methods: {
    onCloseDialog() {
      this.dialog.show = false
    },
    onCreateNew() {
      this.theShowDetail(null)
    },
    theShowDetail(record) {
      if (this.data.crm_store_info == null) {
        this.Bus.ActionFunc('PayIntegral-GetCrmStoreInfo', {}, data => {
          this.$set(this.data, 'crm_store_info', data.map(p => {
            p.store_name = `${p.store_name}(${p.store_id})`
            return p
          }))
          this.theShowDetail2(record)
        })
      } else {
        this.theShowDetail2(record)
      }
    },
    theShowDetail2(record) {
      let me = this
      this.$set(this, 'dialog', {
        show: true,
        type: 'win-store-bind',
        data: {
          record: record,
          store_list: this.data.crm_store_info
        },
        event() {
          me.$set(me, 'dialog', {
            show: false
          })
          me.onRefresh()
        }
      })
    },
    onEdit(record) {
      this.theShowDetail(Object.assign({}, record))
    },
    onDelete(record) {
      let me = this
      this.Bus.msg_confirm(`确定要删除该绑定记录吗?`, _ => {
        this.Bus.ActionFunc('PayIntegral-RemoveStoreBind', {
          bind_id: record.bind_id
        }, data => {
          me.onRefresh()
        })
      })
    },
    onRefresh() {
      let me = this
      let { pageIndex, pageSize } = this.userConfig
      this.Bus.ActionFunc("PayIntegral-GetStoreList", {
        pageIndex: pageIndex,
        pageSize: pageSize,
        filters: {
          normal: me.searchKey.trim()
        }
      }, data => {
        this.$set(this.data, 'stores', data.list)
        this.$set(this.data, 'store_total', data.total)
      })
    },
    onToUrl(url) {
      this.$router.push({
        path: url
      })
      //location.href='/#'+url
    }
  },
  mounted() {
    this.onRefresh()
  }
}
</script>
<style lang="scss">
.tips {
  font-size: 13px;
  padding: 10px 0;
  color: #626161;
}
.admin-index-main {
  width: 1200px;
  margin: 0 auto;
  > .el-main {
    > .el-card {
      margin-bottom: 10px;
    }
  }
  > .el-aside {
    overflow: visible;
    padding-bottom: 10px;

    .el-card {
      margin-bottom: 10px;
    }
    .std-functions {
      .el-link {
        display: block;
      }
      .el-button {
        width: 100%;
        margin-left: 0px;
        margin-bottom: 5px;
      }
    }

    .list {
      padding-bottom: 10px;
      .main {
        > .el-link {
          float: left;
          clear: both;
        }
      }
    }
  }
}
</style>
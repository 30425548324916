<template>
  <el-dialog title="店铺绑定" :visible="true" :before-close="onClose" width="500px" class="win-store-bind">
    <el-container>
      <el-main style="padding:0">
        <el-form ref="detail_form" class="form-jg5" label-width="100px" label-position="right" size="mini">
          <el-form-item label="CRM店铺号">
            <el-select v-model="form.crm_store_id" filterable placeholder="请选择,可搜索" @change="onChangeSelect" allow-create style="width:100%">
              <el-option v-for="item in store_list" :key="item.store_id" :label="item.store_name" :value="item.store_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="CRM店铺名">
            <el-input v-model.trim="form.crm_store_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="线上店铺号">
            <el-input v-model.trim="form.online_id"></el-input>
          </el-form-item>
        </el-form>
      </el-main>
      <el-footer>
        <el-button type="primary" size="mini" icon="el-icon-s-claim" @click="onSave">提交</el-button>
        <el-button type="primary" size="mini" icon="el-icon-refresh-right" @click="onClose">取消</el-button>
      </el-footer>
    </el-container>
  </el-dialog>
</template>
<script>
export default {
  props: {
    windata: Object
  },
  data() {
    return {
      form: {
        bind_id: '',
        crm_store_id: '',
        crm_store_name: '',
        online_id: ''
      },
      store_list: []
    }
  },
  computed: {
    isCreate() {
      return this.Bus.isEmpty(this.form.bind_id)
    },
    storeids() {
      return this.store_list.map(p => p.store_id)
    }
  },
  methods: {
    onClose() {
      this.$emit('cancel')
    },
    onChangeSelect(value) {
      console.log('change!')
      let i = this.storeids.indexOf(value)
      let v = "";
      if (i != -1) {
        v = this.store_list[i].store_name
      }
      this.$set(this.form, 'crm_store_name', v)
    },
    onSave() {
      let form = this.form
      let postData = {
        bind_id: form.bind_id,
        crm_store_id: form.crm_store_id,
        online_id: form.online_id
      }
      let valiResult = this.Bus.validData(postData, {
        require: [
          { prop: 'crm_store_id', name: 'CRM店铺号' },
          { prop: 'online_id', name: '线上店铺号' }
        ]
      })
      if (!valiResult) {
        return
      }

      this.Bus.ActionFunc('PayIntegral-UpdateStoreBind', postData, data => {
        this.Bus.msg_success('保存成功!')
        this.$emit('success')
      });
    },
  },
  mounted() {
    let me = this
    this.$set(this, 'store_list', this.windata.store_list)
    if (this.windata?.record) {
      this.$set(this, 'form', this.windata.record)
    }
  }
}
</script>
<style lang="scss">
.win-store-bind {
  .el-dialog {
    > .el-dialog__body {
      .el-container {
        //min-height: 200px;
        > .el-footer {
          text-align: right;
          height: 40px !important;
        }
      }
    }
  }
}
</style>

export default{
  name:'StdTableBtn',
  tag_name:'std-table-btn',
  props:['label','icon','handler','prop'],
  data(){
    return {
      
    }
  },
  methods: {
    GetConfig(){
      return{
        label:this.label,
        icon:this.icon,
        handler:this.handler
      }
    }
  },
  render(){},
  mounted(){
    
  }
}
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Home",
    component: Home,
    children: [{
        path: 'sysconfig',
        redirect:'sysconfig/index',
        component: {
          render: c => c('router-view')
        },
        children: [{
          path: 'index',
          component: resolve => require(['@/views/Sys/sysconfig'], resolve)
        }]
      },
      {
        path:'log',
        redirect:'log/callback',
        component: {
          render: c => c('router-view')
        },
        children: [{
          path: 'callback_txt',
          component: resolve => require(['@/views/ActionLog/SysLog'], resolve)
        }]
      },
      {
        path: 'UserMgr',
        component: {
          render: c => c('router-view')
        },
        children: [{
          path: 'Index',
          name: "UserMgrIndex",
          component: resolve => require(['@/views/UserMgr'], resolve),
        }, {
          path: "Detail",
          name: 'UserMgrDetail',
          component: resolve => require(['@/views/UserMgr/detail'], resolve)
        }]
      },
      {
        path: 'AuthMgr',
        component: {
          render: c => c('router-view')
        },
        children: [{
          path: 'Index',
          component: resolve => require(['@/views/AuthMgr'], resolve),
        }, {
          path: "Import",
          component: resolve => require(['@/views/AuthMgr/import'], resolve)
        }]
      },
      {
        path: 'TokenMgr',
        component: {
          render: c => c('router-view')
        },
        children: [{
          path: 'Index',
          name: 'TokenMgrIndex',
          component: resolve => require(['@/views/TokenMgr/index'], resolve)
        }, {
          path: 'Detail/:token_id',
          name: 'TokenMgrDetail',
          component: resolve => require(['@/views/TokenMgr/detail'], resolve)
        }, ]
      },
      {
        path: 'SysMgr',
        name: 'SysMgrIndex',
        component: resolve => require(['@/views/AppMgr/admin-index'], resolve)
      }
    ]
  },
  {
    path: '/Login',
    name: 'Login',
    component: resolve => require(['@/views/Login'], resolve)
  },
  {
    path: '/FastLogin',
    name: 'FastLogin',
    component: resolve => require(['@/views/Login/fast-login'], resolve)
  }
];

const router = new VueRouter({
  routes
});

export default router;
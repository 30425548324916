<template>
  <div class="std-row-form">
    <slot v-bind="tool" v-if="!(hideContent&&editor.show)"></slot>
    <div class="flexbox std-row-form__content" v-if="editor.show">
      <el-input :placeholder="placeholder" v-model="editor.value" size="mini" class="flex-1"></el-input>
      <el-button type="primary" size="mini" @click="onSubmit">提交</el-button>
      <el-button type="primary" size="mini" @click="editor.show=false">取消</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'std-row-form',
  tag_name: 'std-row-form',
  props: {
    placeholder: String,
    hideContent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editor: {
        show: false,
        value: ''
      }
    }
  },
  computed: {
    tool() {
      return {
        show: this.show,
        hide: this.hide
      }
    }
  },
  methods: {
    show(val) {
      this.editor.value = val
      this.editor.show = true
    },
    onSubmit() {
      let me = this
      let val = this.editor.value
      this.$emit('success', {
        value: val,
        tool: this.tool
      })
    },
    hide() {
      this.editor.show = false
      this.editor.value = ''
    }
  }
}
</script>
<style lang="scss">
.std-row-form {
  display: inline-block;
  * + .std-row-form__content {
    margin-top: 5px;
  }
}
</style>
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui'
import '@/assets/styles/index.scss'
import '@/assets/styles/general.scss'
import 'element-ui/lib/theme-chalk/index.css'

//工具类
import http from '@/components/utils/http.js'
import Bus from '@/components/utils/Bus.js'
import normalCard from '@/components/normal-card.vue'


Vue.use(ElementUI)
Vue.config.productionTip = false;
Vue.prototype.axios = http
Vue.prototype.Bus = Bus

//检查登录
router.beforeEach((to, from, next) => {
  //let me=router
  //初始化登录信息
  store.commit('InitLoginState')
  if (to.path === '/Login') {
    store.commit('UpdateLoginState', '')
  }
  var user = store.getters.LoginInfo
  if (!user.IsLogin && ['/Login', '/FastLogin'].indexOf(to.path) == -1) {
    //debugger
    //console.log(to.path,router.currentRoute.fullPath)
    //如果地址栏有uid参数,就先尝试使用uid登录,如果登录失败再跳到登录
    let uid = router.currentRoute.query.uid;
    if (!Bus.isEmpty(uid)) {
      next({
        path: '/FastLogin',
        query: {
          uid: uid,
          redirect: to.path
        }
      })
    } else {
      next({
        path: '/Login',
        query: {
          redirect: to.path
        }
      })
    }
  } else {
    next()
  }
})

function reg_comps(comp_config) {
  comp_config.keys().forEach(fileName => {
    // 获取组件配置
    const componentConfig = comp_config(fileName)
    let cfg = componentConfig.default || componentConfig
    if (!cfg.tag_name) return

    // 全局注册组件
    Vue.component(
      cfg.tag_name,
      // 如果这个组件选项是通过 `export default` 导出的，
      // 那么就会优先使用 `.default`，
      // 否则回退到使用模块的根。
      cfg
    )
  })
}
reg_comps(require.context('./components/std-table/', true, /[A-Za-z]\w+\.(vue|js)$/))
reg_comps(require.context('./components/std-nav/', true, /[A-Za-z]\w+\.(vue|js)$/))
reg_comps(require.context('./components/std-row-form/', true, /[A-Za-z]\w+\.(vue|js)$/))

Vue.component('normal-card',normalCard)

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    //保存vue实例引用
    Bus.vueObj = this

    //公开Bus用于测试
    window.BusObj = Bus

    //初始化登录信息
    //this.$store.commit('InitLoginState')

    //阻止拖动时打开新窗口,如有未知影响请尝试注释掉
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    //添加一个日志输出函数,控制控制台日志输出
    window.WritePageLog = function (msg) {
      if (window.enableDebug !== true) {
        return
      }
    }

    //数组转化为对象,可使用参数名字符串或自定义函数取参数名和参数值
    Array.prototype.toObject = function (pro, value) {
      let keyfun, valuefun;
      if (typeof pro == 'string') {
        keyfun = (item) => item[pro]
      } else if (typeof pro == 'function') {
        keyfun = pro
      } else {
        throw new Error("参数[pro]只接受string或function类型的值")
      }
      if (value == undefined) {
        valuefun = (item) => item;
      } else if (typeof value == 'string') {
        valuefun = (item) => item[value]
      } else if (typeof value == 'function') {
        valuefun = value
      } else {
        throw new Error("参数[value]只接受undefined/string/function类型的值")
      }

      let result = {}
      for (const item of this) {
        result[keyfun(item)] = valuefun(item)
      }
      return result;
    }
  }
}).$mount("#app");